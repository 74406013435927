.banner-text-gradient {
  background-image: linear-gradient(180deg, #fff 17.89%, #3af779 233.81%);
}

.products-wrapper {
  background: #fbf9f9;
  /* 
  background: linear-gradient(
    180deg,
    #efffea 0%,
    rgba(229, 232, 236, 0.71) 100%
  ); */
}

.products-text-gradient {
  background-image: linear-gradient(180deg, #000 17.89%, #3af779 233.81%);
}

.product-card-wrapper {
  background: linear-gradient(180deg, #fff 24.47%, rgba(255, 255, 255, 0));
  box-shadow: 10px 40px 68.8px 20px rgba(135, 135, 135, 0.11);
}

.what-we-do-wrapper {
  background: linear-gradient(
    159deg,
    #fff -1.46%,
    rgba(255, 255, 255, 0.51) 108.95%
  );
  box-shadow: 10px 40px 68.8px 20px rgba(135, 135, 135, 0.21);
}
.what-we-do-wrapper .title {
  background-image: linear-gradient(180deg, #001c06 17.89%, #3af779 345.63%);
}

.features-wrapper .title,
.teams-wrapper .title {
  background-image: linear-gradient(180deg, #000 17.89%, #3af779 233.81%);
}

.features-wrapper .pointers-title {
  background-image: linear-gradient(180deg, #000 17.89%, #3af779 233.81%);
}

.problems-wrapper .title {
  background-image: linear-gradient(180deg, #fff 17.89%, #3af779 170.15%);
}

.tab-active {
  border: 1px solid #3bfe7b;
  background-color: #004737 !important;

  color: #3bfe7b !important;
}

.what-we-do-wrapper {
  background: linear-gradient(
    160deg,
    rgba(255, 255, 255, 0.8) -1.97%,
    rgba(255, 255, 255, 0.68) 112.59%
  );
  box-shadow: 0px 24px 60.5px -10px #fff inset,
    10px 16px 68.8px 20px rgba(135, 135, 135, 0.21);
  backdrop-filter: blur(38px);
}

.contact-us-wrapper {
  background-image: url('assets/contact_us.png'),
    radial-gradient(70% 40% at center top, #009b9e71, #009b9e36, #009b9e11),
    linear-gradient(to bottom, #051c1f, #051c1f);
  background-size: cover, cover, cover;
  background-repeat: no-repeat;
  background-position: center top, cover, cover;
}

.contact-us-wrapper .title {
  background-image: linear-gradient(180deg, #fff 17.89%, #3af779 170.15%);
}
/* .three_wheeler-card {
  background-image: url('assets/threeWheeler.png');
} */

.two_wheeler-card {
  background-image: url('assets/two_wheeler.png');
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.three_wheeler-card {
  background-image: url('assets/three_wheeler.png');
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.dealership-wrapper {
  background-image: url('assets/dealerships.png'),
    linear-gradient(159deg, #fff -1.46%, rgba(255, 255, 255, 0.51) 108.95%);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  box-shadow: 10px 40px 68.8px 20px rgba(135, 135, 135, 0.21);
}

@media screen and (max-width: 562px) {
  .dealership-wrapper {
    background-image: url('assets/dealerships_mobile.png');
  }
}
.offering-wrapper .title {
  background-image: linear-gradient(180deg, #000 17.89%, #3af779 233.81%);
}

.offering-wrapper .tab-wrapper {
  border: 1px solid #fff;
  background: linear-gradient(
    160deg,
    rgba(255, 255, 255, 0.07) -1.97%,
    rgba(255, 255, 255, 0.06) 112.59%
  );
  box-shadow: 11.36px 1px 78.156px 22.72px rgba(135, 135, 135, 0.21);
  backdrop-filter: blur(43px);
}

.offering-wrapper .carousel-title {
  background-image: linear-gradient(180deg, #000 17.89%, #3af779 233.81%);
}

.sus-wrapper {
  background-image: url('assets/letterArt.svg'), url('assets/mesh.png');
  background-repeat: no-repeat;
  background-size: auto min(700px, 70%), cover;
  background-position: right 5% bottom 20%, center;
}

@media screen and (max-width: 768px) {
  .sus-wrapper {
    background-image: url('assets/mesh.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.offering-wrapper .desc-wrapper-enter {
  opacity: 0;
}

.offering-wrapper .desc-wrapper-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.offering-wrapper .desc-wrapper-exit {
  opacity: 1;
}

.offering-wrapper .desc-wrapper-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.offering-wrapper .txt-title {
  animation: appear 300ms ease-in-out forwards;
}

.offering-wrapper .txt-wrapper {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 200ms;
}

.offering-wrapper {
  background-image: linear-gradient(
    to bottom,
    #f7f5f2,
    #f7f5f2 60%,
    #fff 60%,
    #fff
  );
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.offering-card-wrapper {
  background-image: linear-gradient(
    159deg,
    #fff -1.46%,
    rgba(255, 255, 255, 0.81) 108%
  );
  box-shadow: 10px 40px 68.8px 20px rgba(135, 135, 135, 0.21);
}

.member-card .name {
  background-image: linear-gradient(180deg, #0b373c 17.89%, #3af779 233.81%);
}

.member-name {
  background-image: linear-gradient(180deg, #000 17.89%, #3af779 233.81%);
}

.modal-wrapper {
  background: linear-gradient(
    159deg,
    #fff -1.46%,
    rgba(255, 255, 255, 0.51) 108.95%
  );
  box-shadow: 10px 40px 68.8px 20px rgba(135, 135, 135, 0.21);
}

.m-c {
  display: inline-block;
  transition: transform 0.2s;
}

.m-c:hover {
  transform: scale(1.5);
}

.m-c:hover + .m-c,
.m-c:has(+ .m-c:hover) {
  transform: scale(1.3);
}

.m-c:hover + .m-c + .m-c,
.m-c:has(+ .m-c + .m-c:hover) {
  transform: scale(1.2);
}

.m-c:hover + .m-c + .m-c + .m-c,
.m-c:has(+ .m-c + .m-c + .m-c:hover) {
  transform: scale(1.1);
}

/* contact form */
.form-wrapper {
  background: linear-gradient(
      0deg,
      rgba(11, 55, 60, 0.5) 0%,
      rgba(11, 55, 60, 0.5) 100%
    ),
    linear-gradient(
      160deg,
      rgba(255, 255, 255, 0.2) -1.97%,
      rgba(255, 255, 255, 0.17) 112.59%
    );
}

/*  footer */
footer {
  background: linear-gradient(to bottom, #061c23, #061c23),
    linear-gradient(to bottom, #08272b, #08272b);
  background-size: contain, cover;
  background-position: center, center;
  background-repeat: no-repeat;
}

.investors-wrapper .tile {
  border-radius: 20px;
  border: 1px solid #fff;
  background: linear-gradient(
    160deg,
    rgba(255, 255, 255, 0.07) -1.97%,
    rgba(255, 255, 255, 0.06) 112.59%
  );
  box-shadow: 11.36px 1px 78.156px 22.72px rgba(135, 135, 135, 0.2);
}

.investors-wrapper .title {
  background: linear-gradient(180deg, #000 17.89%, #3af779 233.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.value-card .img-wrapper {
  border: 1px solid #23984a3a;
  background-color: #0b373ca9;
}

.value-card .title {
  background: linear-gradient(180deg, #fff 17.89%, #3af779 233.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.apart-title {
  background-image: linear-gradient(180deg, #fff 17.89%, #3af779 233.81%);
}

.swiper-pagination-progress {
  height: 3px !important;
  opacity: 0.5;
}

.swiper-pagination-progress .swiper-pagination-progressbar-fill {
  background: white !important;
}

.content-wrapper p {
  @apply font-normal font-regular leading-relaxed pb-6 md:pb-8 text-sm md:text-lg;
}

.content-wrapper h4 {
  @apply capitalize pt-4 font-medium leading-relaxed pb-3 md:pb-4;
}

.content-wrapper ol {
  @apply list-decimal pl-10 space-y-2 font-normal font-regular leading-relaxed pb-6 md:pb-8 text-sm md:text-lg;
}

.content-wrapper .no-bullets {
  @apply list-none pl-10 space-y-2 font-normal font-regular leading-relaxed pb-6 md:pb-8 text-sm md:text-lg;
}
/* 
.carousel-grad {
  background-image: linear-gradient(
    to right,
    #061c23,
    #061c23 0,
    transparent 5%,
    transparent 100%
  );
} */
